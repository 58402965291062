import { useMicroCopy } from "./MicroCopy";

export function createMediaTextCta({
  _uid,
  text,
  filename,
  title,
  readingTime,
  ctaUrl,
  ctaLabel,
  isCtaPlain,
  isSpecial,
  isFile,
  isMagazine,
  magazineName,
  pdfFileUrl,
  tagList,
}) {
  return {
    component: "media-text-cta",
    _uid,
    ...(text && { text: createRichText(text) }),
    ...(filename && {
      media: [createAssetImage(filename)],
    }),
    ...(title && {
      title: createRichText(title),
    }),
    ...(ctaUrl && {
      cta_link: createLink(ctaUrl),
    }),
    reading_time: readingTime,
    cta_label: ctaLabel,
    cta_plain: isCtaPlain,
    is_special: isSpecial,
    is_file: isFile,
    is_magazine: isMagazine,
    magazine_name: magazineName,
    pdf_file_url: pdfFileUrl,
    tag_list: tagList,
  };
}

export function createLink(url) {
  const isExternal = url.match(/^(http:\/\/|https:\/\/)/);
  return {
    linktype: isExternal ? "url" : "story",
    cached_url: isExternal ? "" : url,
    url: isExternal ? url : "",
  };
}

const isString = (text) => typeof text === "string" || text instanceof String;

export function createRichText(texts) {
  const content = (isString(texts) ? [texts] : texts).map((text) => {
    return isString(text)
      ? {
          type: "paragraph",
          content: [
            {
              text,
              type: "text",
            },
          ],
        }
      : text;
  });
  return {
    type: "doc",
    content,
  };
}

export function createAssetImage(filename) {
  return {
    lazy: true,
    component: "asset-image",
    asset: {
      alt: "",
      name: "",
      title: "",
      filename,
      fieldtype: "asset",
      is_external_url: !filename.includes("https://a.storyblok.com"),
    },
  };
}

export function useFacilitiesMediaTextCta(datas) {
  // Only show private facilities
  const filteredData = datas?.filter((item) => item.isPrivate);
  return filteredData.length
    ? filteredData.map((item) => {
        const addressText: string[] = [];
        if (item.address?.length) {
          addressText.push(item.address);
        }
        if (item.addressSupplementy?.length) {
          addressText.push(item.addressSupplementy);
        }
        const zipCodeNCity =
          item.zipCode?.length || item.city?.length
            ? `${item.zipCode} ${item.city}`
            : "";
        if (zipCodeNCity?.length) {
          addressText.push(zipCodeNCity);
        }
        return createMediaTextCta({
          _uid: item.facilityUniqueKey,
          text: addressText,
          filename: item.image,
          title: item.name,
          ctaUrl: item.slug,
          ctaLabel: useMicroCopy("facilitiesnearby.cta.label", ""),
        } as never);
      })
    : [];
}
